import React, { useEffect, useState } from "react";

import UiButtonColor from "../button/UiButtonColor";
import UiInputText from "../inputs/UiInputText";
import UiSelect from "../select/UiSelect";
import UiModal from "./UiModal";

import "./UiModalNotification.scss";
import ProductsAddPage from "../../pages/products/ProductsAddPage";
import ProductsAddPageUtilities from "../../pages/products/utilities/ProductsAddPageUtilities";
import UiTableAdaptive from "../table/UiTableAdaptive";

import UiSearchDropDown from "../inputs/UiSearchDropDown";

import {
  toArrayListKey,
  toArrayImagesListKey,
  fromArrayListKey,
  inArrayValueByKey,
  toArrayLabelListKey,
} from "../../utilities/array";
import { safelyParseJSON } from "../../services/Storage";

function UiModalEditComposition(props) {
  const variantsTitles2 = [
    { sort: false, title: "" },
    { sort: true, title: "Название" },
    { sort: false, title: "Цена (шт)" },
    { sort: true, title: "Кол-во" },
  ];
  const clientsTitles = [
    { sort: false, title: "" },
    { sort: true, title: "Название" },
    { sort: false, title: "Цена (шт)" },
    { sort: true, title: "Кол-во" },
  ];

  const [activeOption, setOption] = useState(null);
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [composition, setComposition] = useState([]);

  const [state, setState] = useState({
    isEditOpen: false,
    isLoad: false,
    isDialogCrop: false,
    isDialogCrop2: false,
    selectedCustomItem: null,
    customId: null,
    customIndex: null,
    addPhoto: null,
    addTitle: "S",
    addText: "",
    composition: [],
    addPhotos: [],
    savedImages: [],
    removeArray: [],
    imagesList: [],
  });

  const [item, setItem] = useState({
    custom_title: null,
    custom_text: null,
    custom_price: null,
  });

  useEffect(() => {
    if (props.item) {
      let _item = props.item;
      _item.custom_composition = safelyParseJSON(_item.custom_composition);
      setItem(_item);
      setComposition( _item.custom_composition );
      console.log("ASD", _item);
    }
  }, [props.modalOpen, props.item]);

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  return (
    <UiModal name="modal-notification" show={props.modalOpen}>
      <div className="modal-container">
        <h3>Редактировать вариант</h3>
        <button className="modal-close" onClick={props.cancelClick}>
          Х
        </button>
        <div className="modal-add-form">
          <UiInputText
            label="Название варианта"
            value={item.custom_title}
            small
            onChange={(val) => setItem({ ...item, custom_title: val })}
          />

          <UiInputText
            label="Описание варианта"
            value={item.custom_text}
            small
            onChange={(val) => setItem({ ...item, custom_text: val })}
          />

          {composition && (
            <UiTableAdaptive
              titleList={variantsTitles2}
              grid={variantsTitles2.length}
              tableList={ProductsAddPageUtilities.convertArrayToAdditional(
                composition,
                (id, itemIndex) => {}
              )}
              search={false}
            />
          )}
          {composition && (
            <UiInputText
              label="Цена варианта (автоматическая)"
              value={ProductsAddPageUtilities.getTotalPrice(
                composition
              )}
              disabled
            />
          )}

          {item.image && (
            <img
              src={item.image}
              style={{ width: "100px", height: "100px", borderRadius: "4px" }}
            />
          )}

 
          <UiSearchDropDown
            label="Выбрать товар из базы 1С"
            value={null}
            showEmptyVal
            items={toArrayLabelListKey(props.compositionList, "custom_title")}
            onChange={(val) => {
              setSelectedStorage(
                inArrayValueByKey(props.compositionList, "id", val)
              );

              setState({
                ...state,
                selectedStorage: inArrayValueByKey(
                  props.compositionList,
                  "id",
                  val
                ),
              });
            }}
          />

          <UiInputText
            id="custom_count"
            label="Кол-во товара:"
            placeholder="кол-во"
            type={"number"}
            onChange={(val) => {
              let _item = state.selectedStorage;
              console.log(_item);
              _item.custom_count = val;
              setSelectedStorage(_item);
              setState({ ...state, selectedStorage: _item });
            }}
          />

          <UiButtonColor
            color="green"
            text="Добавить в состав варианта"
            small={true}
            onClick={() => {
              setState({ ...state, isLoad: true });
              let arr = composition;
              let priceArr = safelyParseJSON(
                state.selectedStorage.custom_sale_price
              );

              let price = ProductsAddPageUtilities.getAdditionalPrice(
                priceArr,
                state.selectedStorage
              );

              arr.push({
                index: arr.length + 1,
                custom_title: state.selectedStorage.custom_title,
                custom_count: state.selectedStorage.custom_count,
                custom_price: price,
                custom_id: state.selectedStorage.custom_id,
              });
              console.log(arr, state.selectedStorage);
              setState({ ...state, composition: arr });

              setComposition(arr);

              setState({ ...state, isLoad: false });
              document.getElementById("custom_count").value = "";
            }}
          />

          <UiButtonColor
            text="Редактировать"
            onClick={() => {  
              let _item = item;
              _item.custom_price = ProductsAddPageUtilities.getTotalPrice( composition );
              props.onSave(_item);
            }}
          />
        </div>
      </div>
    </UiModal>
  );
}

export default UiModalEditComposition;
