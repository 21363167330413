import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getStoredUser as getStoredUserAction,
  showUserInfo as showUserInfoAction,
  logoutUser as logoutUserAction,
} from "../../redux/modules/login";
import {
  getCategoryList as getCategoryListAction,
  getSubCategoryList as getSubCategoryListAction,
} from "../../redux/modules/category";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function UiAnimatedAppLoader(props) {
  const navigate = useNavigate();
  let location = useLocation().key;
  useEffect(() => {
    console.log(location)
    props.getCategoryList();
    props.getStoredUser().then((user) => {
      if(user){
        props
        .showUserInfo(user.api_token, user.id)
        .then((val) => {
          if (val) {
          } else {
            props.logoutUser().then(() => {
              document.location.href = "/";
            });
          }
        });
      } 
 
    })
  }, [location ]);

  
 
  return null;
}

export default connect(({ login, category }) => ({ login, category }), {
  getStoredUser: getStoredUserAction,
  showUserInfo: showUserInfoAction,
  logoutUser: logoutUserAction,
  getSubCategoryList: getSubCategoryListAction,
  getCategoryList: getCategoryListAction,
})(UiAnimatedAppLoader);
