import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  VerticalBarSeries,
  FlexibleXYPlot,
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  LineMarkSeries,
  Crosshair,
  MarkSeries,
  Hint,
  RadialChart,
} from "react-vis";

import OrderConstant from "../../constants/OrderConstant";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiPageText from "../../components/text/UiPageText";
import UiSelectDates from "../../components/select/UiSelectDates";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";
import UiSearchDropDown from "../../components/inputs/UiSearchDropDown";
import UiSwitch from "../../components/switch/UiSwitch";

import { 
  getAllUserStat as getAllUserStatAction,
  getAllDealsStat as getAllDealsStatAction,
} from "../../redux/modules/order";
import { getCompositions as getCompositionsAction } from "../../redux/modules/storage";
import { getWorkers as getWorkersAction } from "../../redux/modules/login";
import { formatDateYMD, formatDatePlusDayYMD } from "../../utilities/date";
import { tableToExcel } from "../../utilities/xls";

import "./StatisticStyle.scss";
import "../../../node_modules/react-vis/dist/style.css";
import { safelyParseJSON } from "../../services/Storage";
import { inArrayValueByKey, toArrayLabelListKey } from "../../utilities/array";

function StatisticPage(props) {
  const [radialData, setRadialData] = useState([]);
  const [radialHint, setRadialHint] = useState(false);

  const [data, setData] = useState([
    { x: 0, y: 8 },
    { x: 0, y: 5 },
    { x: 0, y: 4 },
    { x: 0, y: 9 },
    { x: 0, y: 1 },
  ]);
  const [data2, setData2] = useState([
    { x: OrderConstant.ORDER_TYPES_NAMES[0], y: 8 },
    { x: OrderConstant.ORDER_TYPES_NAMES[1], y: 5 },
    { x: OrderConstant.ORDER_TYPES_NAMES[2], y: 4 },
    { x: OrderConstant.ORDER_TYPES_NAMES[3], y: 9 },
    { x: OrderConstant.ORDER_TYPES_NAMES[4], y: 1 },

    { x: OrderConstant.ORDER_TYPES_NAMES[6], y: 1 },
  ]);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: true, title: "" },
  ];
  const ordersTitles = [
    { sort: true, title: "" },
    { sort: true, title: OrderConstant.ORDER_TYPES_NAMES[0] },
    { sort: true, title: OrderConstant.ORDER_TYPES_NAMES[1] },
    { sort: true, title: OrderConstant.ORDER_TYPES_NAMES[2] },
    { sort: true, title: OrderConstant.ORDER_TYPES_NAMES[3] },
    { sort: true, title: OrderConstant.ORDER_TYPES_NAMES[4] },
    { sort: true, title: OrderConstant.ORDER_TYPES_NAMES[5] },
    { sort: true, title: OrderConstant.ORDER_TYPES_NAMES[6] },
    { sort: true, title: "Всего оформлено" },
  ];

  const daysReportTitles = [
    { sort: true, title: "Номер" },
    { sort: true, title: "Тип" },
    { sort: true, title: "Сумма" },
    { sort: true, title: "Дата оплаты" },
    { sort: true, title: "Дата создания" },
  ];

  const storageTitles = [
    { sort: true, title: "" },
    { sort: true, title: "Название" },
    { sort: true, title: "Цена" },
    { sort: true, title: "Кол-во" },
    { sort: true, title: "Сумма" },
  ];

  const nomenclatureTitles = [
    { sort: true, title: "Номенклатура" },
    { sort: true, title: "Количество" },
    { sort: true, title: "Сумма" },
  ];

  const [compositionList, setCompositionList] = useState([]);
  const [tableListOrders, setTableListOrders] = useState([]);
  const [date, setDate] = useState(formatDateYMD(new Date(), "-"));

  const [date1, setDate1] = useState(formatDateYMD(new Date(), "-"));
  const [date2, setDate2] = useState(
    formatDatePlusDayYMD(new Date(), -30, "-")
  );
  const [date3, setDate3] = useState( formatDatePlusDayYMD(new Date(), +1, "-") );
  const [date4, setDate4] = useState(formatDateYMD(new Date(), "-"));


  const [totalNal, setTotalNal] = useState(0);
  const [totalBs, setTotalBs] = useState(0);

  const [totalMT0, setTotalMT0] = useState(0);
  const [totalMT1, setTotalMT1] = useState(0);

  const [orders, setOrders] = useState([]);
  const [ordersSelfDelivery, setOrdersSelfDelivery] = useState([]);

  const [workersList, setWorkersList] = useState([]);
  const [workersOrdersList, setWorkersOrdersList] = useState([]);

  const [storages, setStorages] = useState([]);
  const [storages2, setStorages2] = useState([]);

  const [dealsOnlinePay, setDealsOnlinePay] = useState([]);
  const [dealsOfflinePay, setDealsOfflinePay] = useState([]);

  const [nomenclatureList, setNomenclatureList] = useState([]);
  const [selectedNomenclature, setSelectedNomenclature] = useState(null);

  const [isPayNomenclature, setIsPayNomenclature] = useState(false);
  const [checkNomenclature, setCheckNomenclature] = useState(false);

  useEffect(() => {
    props.getCompositions().then((res) => setCompositionList(res));
  }, []);

  useEffect(() => {
    props.getAllUserStat(date2, date1); 
  }, [date1, date2]);

  useEffect(() => { 
    props.getAllDealsStat(date4, date3);
  }, [date3, date4]);

  useEffect(() => {
    props.getAllUserStat(date, date).then((data) => {
      let bs = 0;
      let nal = 0;
      let a1 = [],
        a2 = [];

      data.paid_deals.map((item) => {
        if (item.is_pay == 1) {
          if (item.payment_type == 0) {
            bs += item.price;
            a1.push([
              {
                text: `${item.id} `,
              },
              {
                text: `${item.payment_type == 0 ? "Сайт" : "На карту"} `,
              },
              {
                text: `${item.price} р`,
              },
              {
                text: `${item.payment_date} | ${item.manual_payment_date}`,
              },
              {
                text: `${item.created_at}`,
              },
            ]);
          } else {
            nal += item.price;
            a2.push([
              {
                text: `${item.id} `,
              },
              {
                text: `${item.payment_type == 0 ? "Сайт" : "На карту"} `,
              },
              {
                text: `${item.price} р`,
              },
              {
                text: `${item.manual_payment_date}`,
              },
              {
                text: `${item.created_at}`,
              },
            ]);
          }
        }
      });
      setTotalNal(nal);
      setTotalBs(bs);
      setDealsOnlinePay(a1);
      setDealsOfflinePay(a2);
    });
  }, [date]);

  useEffect(() => {
    if (props.login.user) {
      props.getWorkers();
    }
  }, [props.login.user]);

  useEffect(() => {
    if (props.login.usersWorker) {
      setWorkersList(props.login.usersWorker);
    }
  }, [props.login.usersWorker]);

  useEffect(() => {
    if (props.order.statistics) {
      let arr = [];
      let arr2 = [];

      arr2.push({
        theta: props.order.statistics.totalPay,
        color: "#059bff",
        name: `Всего оплаченно ${props.order.statistics.totalPay} р`,
      });
      arr2.push({
        theta: props.order.statistics.totalNotPay,
        color: "#ff6384",
        name: `Всего не оплачено ${props.order.statistics.totalNotPay} р`,
      });
      setRadialData(arr2);

      arr.push([
        {
          statusInfo: "Всего оплаченно",
          statusColor: OrderConstant.ORDER_TYPES_COLORS[0],
        },
        { text: `${props.order.statistics.totalPay} р` },
      ]);
      arr.push([
        {
          statusInfo: "Всего заказов",
          statusColor: OrderConstant.ORDER_TYPES_COLORS[1],
        },
        { text: `${props.order.statistics.totalCount}` },
      ]);
      arr.push([
        {
          statusInfo: "Всего не оплачено",
          statusColor: OrderConstant.ORDER_TYPES_COLORS[2],
        },
        { text: `${props.order.statistics.totalNotPay} р` },
      ]);

      arr.push([
        {
          statusInfo: OrderConstant.ORDER_TYPES_NAMES[0],
          statusColor: OrderConstant.ORDER_TYPES_COLORS[0],
        },
        { text: `${props.order.statistics.status0}` },
      ]);
      arr.push([
        {
          statusInfo: OrderConstant.ORDER_TYPES_NAMES[1],
          statusColor: OrderConstant.ORDER_TYPES_COLORS[1],
        },
        { text: `${props.order.statistics.status1}` },
      ]);
      arr.push([
        {
          statusInfo: OrderConstant.ORDER_TYPES_NAMES[2],
          statusColor: OrderConstant.ORDER_TYPES_COLORS[2],
        },
        { text: `${props.order.statistics.status2}` },
      ]);
      arr.push([
        {
          statusInfo: OrderConstant.ORDER_TYPES_NAMES[3],
          statusColor: OrderConstant.ORDER_TYPES_COLORS[3],
        },
        { text: `${props.order.statistics.status3}` },
      ]);
      arr.push([
        {
          statusInfo: OrderConstant.ORDER_TYPES_NAMES[4],
          statusColor: OrderConstant.ORDER_TYPES_COLORS[4],
        },
        { text: `${props.order.statistics.status4}` },
      ]);
      arr.push([
        {
          statusInfo: OrderConstant.ORDER_TYPES_NAMES[6],
          statusColor: OrderConstant.ORDER_TYPES_COLORS[6],
        },
        { text: `${props.order.statistics.status5}` },
      ]);

      setData([
        { x: 0, y: parseInt(props.order.statistics.status0) },
        { x: 1, y: parseInt(props.order.statistics.status1) },
        { x: 2, y: parseInt(props.order.statistics.status2) },
        { x: 3, y: parseInt(props.order.statistics.status3) },
        { x: 4, y: parseInt(props.order.statistics.status4) },
        { x: 5, y: 0 },
        { x: 6, y: parseInt(props.order.statistics.status5) },
      ]);
      setTableListOrders(arr);

      let time = [[], [], [], [], [], [], []];
      let worker = [];
      let timeTitle = [
        "6:00 - 9:00",
        "9:00 - 12:00",
        "12:00 - 15:00",
        "15:00 - 18:00",
        "18:00 - 21:00",
        "21:00 - 00:00",
        "Остальное",
      ];
      let timeArr = [];
      let timeArr2 = [];
      let _totalMT0 = 0;
      let _totalMT1 = 0;
      props.order.statistics.deals.map((item) => {
        let tt = item.time
          ? parseInt(item.time.split("-")[0].split(":")[0])
          : 0;
        let tt2 = item.time
          ? parseInt(item.time.split("-")[1].split(":")[0])
          : 0;
        if (tt == 21 && tt2 == 0) tt2 = 24;

        if (tt >= 6 && tt2 <= 9 && tt <= tt2) time[0].push(item);
        else if (tt >= 9 && tt2 <= 12 && tt <= tt2) time[1].push(item);
        else if (tt >= 12 && tt2 <= 15 && tt <= tt2) time[2].push(item);
        else if (tt >= 15 && tt2 <= 18 && tt <= tt2) time[3].push(item);
        else if (tt >= 18 && tt2 <= 21 && tt <= tt2) {
          time[4].push(item);
        } else if (tt >= 21 && tt2 <= 24 && tt <= tt2) {
          time[5].push(item);
        } else {
          time[6].push(item);
        }

        if (item.worker_id) {
          let workerArr = { id: item.worker_id, arr: [] };
          if (worker[item.worker_id]) workerArr = worker[item.worker_id];
          workerArr.arr.push(item.address_a);
          worker[item.worker_id] = workerArr;
        }
      });

      worker = worker.filter((item) => {
        return item != null;
      });

      time.map((item, index) => {
        _totalMT0 += item.filter((f) => {
          return f.moving_types == 0;
        }).length;
        timeArr.push([
          {
            statusInfo: timeTitle[index],
            statusColor: OrderConstant.ORDER_TYPES_COLORS[index],
          },

          {
            text: `${
              item.filter((f) => {
                return f.status == 0 && f.moving_types == 0;
              }).length
            } `,
          },
          {
            text: `${
              item.filter((f) => {
                return f.status == 1 && f.moving_types == 0;
              }).length
            } `,
          },
          {
            text: `${
              item.filter((f) => {
                return f.status == 2 && f.moving_types == 0;
              }).length
            } `,
          },
          {
            text: `${
              item.filter((f) => {
                return f.status == 3 && f.moving_types == 0;
              }).length
            } `,
          },
          {
            text: `${
              item.filter((f) => {
                return f.status == 4 && f.moving_types == 0;
              }).length
            } `,
          },
          {
            text: `${
              item.filter((f) => {
                return f.status == 5 && f.moving_types == 0;
              }).length
            } `,
          },
          {
            text: `${
              item.filter((f) => {
                return f.status == 6 && f.moving_types == 0;
              }).length
            } `,
          },

          {
            text: `${
              item.filter((f) => {
                return f.moving_types == 0;
              }).length
            } `,
          },
        ]);
      });

      time.map((item, index) => {
        _totalMT1 += item.filter((f) => {
          return f.moving_types == 1;
        }).length;

        timeArr2.push([
          {
            statusInfo: timeTitle[index],
            statusColor: OrderConstant.ORDER_TYPES_COLORS[index],
          },

          {
            text: `${
              item.filter((f) => {
                return f.status == 0 && f.moving_types == 1;
              }).length
            } `,
          },
          {
            text: `${
              item.filter((f) => {
                return f.status == 1 && f.moving_types == 1;
              }).length
            } `,
          },
          {
            text: `${
              item.filter((f) => {
                return f.status == 2 && f.moving_types == 1;
              }).length
            } `,
          },
          {
            text: `${
              item.filter((f) => {
                return f.status == 3 && f.moving_types == 1;
              }).length
            } `,
          },
          {
            text: `${
              item.filter((f) => {
                return f.status == 4 && f.moving_types == 1;
              }).length
            } `,
          },
          {
            text: `${
              item.filter((f) => {
                return f.status == 5 && f.moving_types == 1;
              }).length
            } `,
          },
          {
            text: `${
              item.filter((f) => {
                return f.status == 6 && f.moving_types == 1;
              }).length
            } `,
          },

          {
            text: `${
              item.filter((f) => {
                return f.moving_types == 1;
              }).length
            } `,
          },
        ]);
      });

      let storageArr = [];
      let compArr = [];
      props.order.statistics.compositionsList.map((item, index) => {
        let _arr = safelyParseJSON(item.composition);
        let storageCount = props.order.statistics.compositions.filter(
          (i) => i == item.id
        ).length;
        _arr.map((cItem) => {
          cItem.custom_count = cItem.custom_count * storageCount;
        });
        compArr = compArr.concat(_arr);
        item.count = storageCount;
      });
      let ccArr = [];
      compArr.map((item, index) => {
        let f = false;
        let i = 0;
        let c = 0;
        ccArr.map((ccItem, ccIndex) => {
          if (ccItem.custom_id == item.custom_id) {
            f = true;
            i = ccIndex;
            c = item.custom_count;
          }
        });
        if (!f) ccArr.push(item);
        else ccArr[i].custom_count = ccArr[i].custom_count + c;
      });

      let storageArr2 = [];
      let compArr2 = [];
      props.order.statistics.compositionsList2.map((item, index) => {
        let _arr = safelyParseJSON(item.composition);
        let storageCount = props.order.statistics.compositions2.filter(
          (i) => i == item.id
        ).length;
        _arr.map((cItem) => {
          cItem.custom_count = cItem.custom_count * storageCount;
        });
        compArr2 = compArr2.concat(_arr);
        item.count = storageCount;
      });
      let ccArr2 = [];
      compArr2.map((item, index) => {
        let f = false;
        let i = 0;
        let c = 0;
        ccArr2.map((ccItem, ccIndex) => {
          if (ccItem.custom_id == item.custom_id) {
            f = true;
            i = ccIndex;
            c = item.custom_count;
          }
        });
        if (!f) ccArr2.push(item);
        else ccArr2[i].custom_count = ccArr2[i].custom_count + c;
      });

 

      /*
     
      */

      setStorages(storageArr);
      setStorages2(storageArr2);
      setOrders(timeArr);
      setOrdersSelfDelivery(timeArr2);
      setTotalMT0(_totalMT0);
      setTotalMT1(_totalMT1);
      setWorkersOrdersList(worker); 
    }
  }, [props.order.statistics ]);


  useEffect(() => {
    if (props.order.statisticsDeal) {
 

      //nomenclature filter
      let storageArr2 = []; 
      let ccArr = [];
      let ccArr2 = [];
      let storageArr = [];
      let compArr3 = [];
  
      let ccArr3 = [];

      if(!isPayNomenclature){
        let tempArr = JSON.parse( JSON.stringify(props.order.statisticsDeal.compositionsPayList2) );
        tempArr.map((item, index) => {
          let f = false;
          let i = 0;
          let c = 0; 
          ccArr3.map((ccItem, ccIndex) => {
            if (ccItem.custom_id == item.custom_id) {
              f = true;
              i = ccIndex;
              c = item.custom_count;
            }
          });
          if (!f) ccArr3.push(item);
          else ccArr3[i].custom_count = ccArr3[i].custom_count + c;
        });
      }


      let tempArr2 = JSON.parse( JSON.stringify(props.order.statisticsDeal.compositionsPayList) );
      tempArr2.map((item, index) => {
          let f = false;
          let i = 0;
          let c = 0; 
          ccArr3.map((ccItem, ccIndex) => {
            if (ccItem.custom_id == item.custom_id) {
              f = true;
              i = ccIndex;
              c = item.custom_count;
            }
          });
          if (!f) ccArr3.push(item);
          else ccArr3[i].custom_count = ccArr3[i].custom_count + c;

  
        });
    
      
      let nomenclatureArrList = [];

      ccArr3.map((item, index) => {
          if (
            !checkNomenclature ||
            (checkNomenclature && selectedNomenclature == item.custom_id)
          )
            nomenclatureArrList.push([
              {
                text: `${item.custom_title} `,
              },
              {
                text: item.custom_count,
              },
              {
                text: item.custom_count * item.custom_price,
              },
            ]);
    
      });
 

      /*
     
      */

      
      setNomenclatureList(nomenclatureArrList);
    }
  }, [props.order.statisticsDeal, checkNomenclature, isPayNomenclature]);


  function print() {
    var str = "";
    var td = "";
    var i = 0;
    var ix = 0;
    workersOrdersList.map((item, index) => {
      ix++;
      i++;
      let name = workersList.filter((w) => {
        return w.id == item.id;
      })[0]?.name;
      td =
        td +
        '<td  width="200" height="90" style="text-align: center; vertical-align: middle; line-height: 90px;" >  <b>' +
        name +
        "</b> " +
        "   </td>";
      td =
        td +
        '<td  width="200" height="90" style="text-align: center; vertical-align: middle; line-height: 90px;">  <b>' +
        item.arr.length +
        "</b> " +
        "  </td>";
      td =
        td +
        '<td  width="1200" height="90" style="text-align: center; vertical-align: middle; line-height: 90px;">  <b>' +
        item.arr.join("<br> ,  ") +
        "</b> " +
        "   </td>";
      str = str + "<tr>" + td + "</tr>";
      td = "";
    });
    var divContents =
      '<table  WIDTH="100%"><thead><tr> <td><b>Курьер</b></td> <td><b>Кол-во поездок</b></td> <td><b>Список адресов</b></td></tr></thead>' +
      str +
      "</table>";

    tableToExcel(
      divContents,
      "Отчет по курьерам",
      `Отчет_${formatDateYMD(date2, ".")}-${formatDateYMD(date1, ".")}.xls`
    );
  }

  return (
    <div id="page" className="statistic-page">
      <section className="statistic-info ui-grid">
        <div className="statistic-info-text">
          <UiPageText title="Статистика" />
        </div>
      </section>

      <section className="page-content">
        <p>ОТЧЕТ НОМЕНКЛАТУРЫ </p>

        <div className="statistic-wrap statistic-dates-select">
          <UiSelectDates
            onChange={(date) => setDate4(formatDateYMD(new Date(date), "-"))}
            date={new Date(date4)}
          />
          <span>-</span>
          <UiSelectDates
            onChange={(date) => setDate3(formatDateYMD(new Date(date), "-"))}
            date={new Date(date3)}
          />
        </div>

        <div className="statistic-wrap statistic-dates-select">
          <UiSwitch
            label="Только ОНЛАЙН ОПЛАТА НА САЙТЕ"
            value={isPayNomenclature}
            onChange={(val) => {
              setIsPayNomenclature(val);
            }}
          />

<UiSwitch
            label="Только выбранная номенклатура"
            value={checkNomenclature}
            onChange={(val) => {
              setCheckNomenclature(val);
            }}
          />


          <UiSearchDropDown
            label="НОМЕНКЛАТУРА"
            value={null}
            showEmptyVal
            items={toArrayLabelListKey(compositionList, "custom_title")}
            onChange={(val) => {
              setSelectedNomenclature(
                inArrayValueByKey(compositionList, "id", val).custom_id
              );
            }}
          />
        </div>

        <div className="statistic-wrap">
          <UiTableAdaptive
            pageSize={nomenclatureList.length}
            titleList={nomenclatureTitles}
            grid={nomenclatureTitles.length}
            tableList={nomenclatureList}
            search={false}
          />
        </div>
      </section>

      <section className="page-content">
        <p>ОТЧЕТ ЗА ДЕНЬ </p>

        <div className="statistic-wrap statistic-dates-select">
          <UiSelectDates
            onChange={(date) => setDate(formatDateYMD(new Date(date), "-"))}
            date={new Date(date)}
          />
        </div>
        <div className="statistic-wrap">
          <b>БЕЗ НАЛ - {totalBs} р </b>
          <br />
          <b>НАЛ - {totalNal} р </b>
          <br />
        </div>

        <div className="statistic-wrap">
          <UiTableAdaptive
            titleList={daysReportTitles}
            grid={daysReportTitles.length}
            tableList={dealsOnlinePay}
            search={false}
          />

          <UiTableAdaptive
            titleList={daysReportTitles}
            grid={daysReportTitles.length}
            tableList={dealsOfflinePay}
            search={false}
          />
        </div>
      </section>

      <section className="page-content">
        <p>Период</p>

        <div className="statistic-wrap statistic-dates-select">
          <UiSelectDates
            onChange={(date) => setDate2(formatDateYMD(new Date(date), "-"))}
            date={new Date(date2)}
          />
          <span>-</span>
          <UiSelectDates
            onChange={(date) => setDate1(formatDateYMD(new Date(date), "-"))}
            date={new Date(date1)}
          />

          <UiButtonColor
            color="indigo"
            text={"Статистика курьеров"}
            small={true}
            onClick={() => print()}
          />
        </div>

        <div className="statistic-wrap">
          <div className="statistic-orders">
            <div className="statistic-order-graph">
              <XYPlot height={400} width={520}>
                <HorizontalGridLines />
                <VerticalBarSeries data={data} />
                <HorizontalGridLines />
                <XAxis
                  tickLabelAngle={0}
                  tickFormat={(v) => OrderConstant.ORDER_TYPES_NAMES[v]}
                />
                <YAxis />
              </XYPlot>
            </div>
            <div className="statistic-order-graph center">
              <div className="graph-legend">
                <div className="graph-legend-item blue">
                  <span>Всего оплачено</span>
                </div>
                <div className="graph-legend-item pink">
                  <span>Всего не оплачено</span>
                </div>
              </div>

              <RadialChart
                data={radialData}
                innerRadius={100}
                radius={180}
                width={400}
                height={400}
                padAngle={0.04}
                const
                colorType="literal"
                getAngle={(d) => d.theta}
                getLabel={(d) => d.name}
                getColor={(d) => d.color}
                animation={"gentle"}
                className={"donut-chart-example"}
                onValueMouseOver={(v) => setRadialHint(v)}
                onSeriesMouseOut={(v) => setRadialHint(false)}
              >
                {radialHint !== false && (
                  <Hint value={radialHint}>
                    <div className="rv-hint__content">{` ${radialHint.name}`}</div>
                  </Hint>
                )}
              </RadialChart>
            </div>
          </div>
        </div>

        <div className="statistic-info-text">
          <UiPageText
            title={`Список проданных букетов в период Комсомольский`}
          />
        </div>

        <div className="statistic-wrap">
          <UiTableAdaptive
            titleList={storageTitles}
            grid={storageTitles.length}
            tableList={storages}
            search={false}
          />
        </div>

        <div className="statistic-info-text">
          <UiPageText title={`Список проданных букетов в период Белецкого`} />
        </div>

        <div className="statistic-wrap">
          <UiTableAdaptive
            titleList={storageTitles}
            grid={storageTitles.length}
            tableList={storages2}
            search={false}
          />
        </div>

        <div className="statistic-info-text">
          <UiPageText title={`Заказы только с Доставкой ${totalMT0}`} />
        </div>

        <div className="statistic-wrap">
          <UiTableAdaptive
            titleList={ordersTitles}
            grid={ordersTitles.length}
            tableList={orders}
            search={false}
          />
        </div>

        <div className="statistic-info-text">
          <UiPageText title={`Заказы только с Самовывозом ${totalMT1}`} />
        </div>

        <div className="statistic-wrap">
          <UiTableAdaptive
            titleList={ordersTitles}
            grid={ordersTitles.length}
            tableList={ordersSelfDelivery}
            search={false}
          />
        </div>

        <div className="statistic-info-text">
          <UiPageText
            title={`Заказы Доставка + Самовывоз в выбранный период ${
              totalMT1 + totalMT0
            }`}
          />
        </div>

        <div className="statistic-wrap">
          <UiTableAdaptive
            titleList={clientsTitles}
            grid={clientsTitles.length}
            tableList={tableListOrders}
            search={false}
          />
        </div>
      </section>
    </div>
  );
}

export default connect(
  ({ order, storage, login }) => ({ order, storage, login }),
  {
    getAllUserStat: getAllUserStatAction,
    getWorkers: getWorkersAction,
    getCompositions: getCompositionsAction,
    getAllDealsStat: getAllDealsStatAction,
  }
)(StatisticPage);
