import React, { useEffect, useState } from "react";
import { addFile, storeData, safelyParseJSON } from "../../services/Storage";

import OrderConstant from "../../constants/OrderConstant";

import {
  formatDateDMY,
  formatDateYMD,
  formatDateHM,
} from "../../utilities/date";
import {
  toArrayList,
  toArrayListKey,
  inArrayValueByKey,
  toArrayLabelListKey,
} from "../../utilities/array";
import {
  getTotalPrice,
  getSalePrice,
  resortOrderComposition,
  getPrice,
} from "../../utilities/order";

import { ReactComponent as SvgCalendar } from "../../assets/images/deal/calendar.svg";
import { ReactComponent as SvgClose } from "../../assets/images/ui/close.svg";
import { ReactComponent as SvgInfo } from "../../assets/images/deal/info.svg";
import { ReactComponent as SvgCube } from "../../assets/images/deal/cube.svg";
import { ReactComponent as SvgPeople } from "../../assets/images/deal/people.svg";

import UiInputText from "../inputs/UiInputText";
import UiModal from "./UiModal";

import "./UiModalDeal.scss";
import UiDropDown from "../inputs/UiDropDown";
import UiSearchDropDown from "../inputs/UiSearchDropDown";
import UiTextArea from "../inputs/UiTextArea";
import UiInputTimeRange from "../inputs/UiInputTimeRange";
import UiSwitch from "../switch/UiSwitch";

import UiCardAddPhoto from "../cards/UiCardAddPhoto";
import UiTextInputMasked from "../inputs/UiTextInputMasked";
import UiButtonColor from "../button/UiButtonColor";
import UiTableAdaptive from "../table/UiTableAdaptive";

import UiLoader from "../../components/modals/UiLoader";

import Env from "../../services/Env";

function UiModalDealAdd(props) {
  const liftTypes = OrderConstant.LIFT_TYPES;

  const [load, setLoad] = useState(false);
  const [discountP, setDiscountP] = useState(0);
  const [itemComposition, setItemComposition] = useState(null);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: false, title: "" },
    { sort: true, title: "Название" },
    { sort: true, title: "Кол-во" },
    { sort: false, title: "Стоимость" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);
  const [tableArrListOrders, setTableArrListOrders] = useState([]);
  const [specialTableListOrders, setSpecialTableListOrders] = useState([]);

  const [managersList, setManagersList] = useState([]);

  const [compositionList, setCompositionList] = useState([]);
  const [selectedStorage, setSelectedStorage] = useState(null);

  const [showCustom, setShowCustom] = useState(false);
  const [customTitle, setCustomTitle] = useState(null);
  const [customCount, setCustomCount] = useState(null);
  const [customPrice, setCustomPrice] = useState(false);

  const [hasComposition, setHasComposition] = useState(false);

  const [order, setOrder] = useState(OrderConstant.ORDER);

  const [userPhone, setUserPhone] = useState(null);

  function init() {
    var DELIVERY_TARIFF = 20,
      MINIMUM_COST = 500,
      myMap = new window.ymaps.Map("map", {
        center: [60.906882, 30.067233],
        zoom: 9,
        controls: [],
      }),
      routePanelControl = new window.ymaps.control.RoutePanel({
        options: {
          showHeader: true,
          title: "Расчёт доставки",
        },
      }),
      zoomControl = new window.ymaps.control.ZoomControl({
        options: {
          size: "small",
          float: "none",
          position: {
            bottom: 145,
            right: 10,
          },
        },
      });
    routePanelControl.routePanel.options.set({
      types: {
        auto: true,
      },
    });
    routePanelControl.routePanel.state.set({
      fromEnabled: false,
      from: order.address_a,
      to: "г. Тюмень, ул. Малыгина, 75",
      type: "auto",
    });
    myMap.controls.add(routePanelControl).add(zoomControl);
    routePanelControl.routePanel.getRouteAsync().then((route) => {
      route.model.setParams(
        {
          results: 1,
        },
        true
      );
      route.model.events.add("requestsuccess", () => {
        var activeRoute = route.getActiveRoute();
        if (activeRoute) {
          var length = route.getActiveRoute().properties.get("distance");
          calculate(Math.round(length.value / 1000));
        }
      });
    });

    function calculate(routeLength) {
      console.log("distance", routeLength);
      updateOrderObj("distance", routeLength);
    }
  }

  useEffect(() => {
    let _arr = toArrayListKey(
      props.managers.filter((item) => item.type > 0),
      "name"
    );

    setManagersList(_arr);
  }, [props.managers]);

  useEffect(() => {
    if (window.ymaps) window.ymaps.ready(init);
  }, [order.address_a]);

  useEffect(() => {
    setCompositionList(props.compositionList);
  }, [props.compositionList]);

  useEffect(() => {
    if (props.data) {
      let _order = props.data;
      if (_order.address_a == null || _order.address_a == "")
        _order.address_a = "";
      if (_order.comment == null || _order.comment == "") _order.comment = "";
      if (_order.text == null || _order.text == "") _order.text = "";
      if (_order.time == null || _order.time == "") _order.time = "";
      if (_order.user_name == null || _order.user_name == "")
        _order.user_name = "";
      if (_order.user_phone == null || _order.user_phone == "")
        _order.user_phone = "";
      if (_order.postcard == null || _order.postcard == "")
        _order.postcard = "";

      if (typeof _order.name === "undefined") _order.name = order.name;
      if (typeof _order.phone === "undefined") _order.phone = order.phone;
      if (typeof _order.is_pay === "undefined") _order.is_pay = order.is_pay;

      console.log(props.data);
      _order.isNew = false;
      setOrder(_order);
      setUserPhone(_order.phone);
      setDiscountP(props.data.price_discount);
    } else {
      let _order = JSON.parse(JSON.stringify(OrderConstant.ORDER));
      console.log("_order_order_order", _order);
      _order.date = new Date();
      if (_order.address_a == null || _order.address_a == "")
        _order.address_a = "";
      if (_order.comment == null || _order.comment == "") _order.comment = "";
      if (_order.text == null || _order.text == "") _order.text = "";
      if (_order.time == null || _order.time == "") _order.time = "";
      if (_order.user_name == null || _order.user_name == "")
        _order.user_name = "";
      if (_order.user_phone == null || _order.user_phone == "")
        _order.user_phone = "";
      if (_order.postcard == null || _order.postcard == "")
        _order.postcard = "";

      setShowCustom(false);
      setCustomTitle(null);
      setCustomCount(null);
      setCustomPrice(false);
      setDiscountP(0);
      setSpecialTableListOrders([]);
      console.log("_order", _order);
      setOrder(_order);
    }
  }, [props.data, props.modalActive]);

  useEffect(() => {
    if (typeof order.composition !== "undefined") {
      let arr = [],
        arr2 = [];
 
      let list = resortOrderComposition(props.storage, order.composition);

      let _saleDiscount = 0;
      if (
        list.filter((item) => {
          return item.sale > 0;
        }).length > 0
      ) {
        let _arr = list.filter((item) => {
          return item.sale > 0;
        });
        _arr.map((item) => {
          if (item.count >= item.sale_count) {
            _saleDiscount = 100 - (item.sale * 100) / item.price;
          }
        });
      }
      updateOrderObj(
        "price_discount",
        _saleDiscount,
        order.is_pay == 0 && hasComposition
      );

      list.map((item) => {
        arr2.push(item);
        arr.push([
          {
            button: "delete",
            openClick: () => {
              let c = order.composition.split(",");
              c = c.filter((cItem) => {
                return parseInt(cItem) != parseInt(item.id);
              });
              updateOrderObj("composition", c.join(","));
            },
          },
          { image: `${Env.PUBLIC_URL}${item.images[0]}` },
          { text: item.title },
          {
            input: item.count,
            onInput: (val) => {
              let c = order.composition.split(",");
              c = c.filter((cItem) => {
                return parseInt(cItem) != parseInt(item.id);
              });
              for (let i = 0; i < val; i++) c.push(item.id);
              updateOrderObj("composition", c.join(","), true);
            },
          },
          { text: `${getPrice(item, compositionList)} ₽` },
        ]);
      });

      setTableListOrders(arr);
      setTableArrListOrders(arr2);
    }
  }, [order.composition, compositionList]);

  useEffect(() => {
    if (props.compositionData) {
      let arr = [];
      props.compositionData.map((item) => {
        if (order.is_pay == 0) {
          arr.push([
            {
              button: "delete",
              openClick: () => {
                props.deleteComposition(item.id);
              },
            },
            { image: item?.custom_image },
            { text: item.custom_title },
            { text: item.custom_count },
            { text: `${item.custom_price} ₽` },
          ]);
        } else {
          arr.push([
            { text: "" },
            { image: item?.custom_image },
            { text: item.custom_title },
            { text: item.custom_count },
            { text: `${item.custom_price} ₽` },
          ]);
        }
      });
      setSpecialTableListOrders(arr);
    }
  }, [props.compositionData]);

  /*

  useEffect(() => { 

    let _price = getTotalPrice(
      tableListOrders,
      specialTableListOrders,
      order.price_delivery,
      order.price_discount,
      false
    );

    if(_price != order.price){
      updateOrderObj(null, null, true); 
    } 


  }, [  tableListOrders, specialTableListOrders, order.price_delivery, order.price_discount])

  */

  useEffect(() => {
    setLoad(false);
  }, [props.modalActive]);

  /**
   *
   * @param {string} key
   * @param {any} val
   */
  function updateOrderObj(key = null, val = null, _edit = false) {
    let _obj = order;
    if (key) _obj[key] = val;

    if (_edit) {
      let price = getTotalPrice(
        tableListOrders,
        specialTableListOrders,
        _obj.price_delivery,
        _obj.price_discount,
        _obj.price_discount_bonus,
        false
      );
      _obj["price"] = price;
    }
    setOrder({ ..._obj });
    if (_edit) props.update(_obj);
  }

  /**
   *
   */

  function addProductFrom1C() {
    let priceArr = safelyParseJSON(selectedStorage.custom_sale_price);
    let price = selectedStorage.custom_price;
    if (priceArr.length > 0) {
      priceArr.map((item) => {
        if (
          item.count_lower <= selectedStorage.custom_count &&
          item.count_upper >= selectedStorage.custom_count
        ) {
          if (item.custom_price.includes("%")) {
            let pr = item.custom_price.replace("%", "");
            pr =
              (selectedStorage.custom_price -
                (pr / 100) * selectedStorage.custom_price) *
              selectedStorage.custom_count;
            console.log(selectedStorage);
            price = Math.round(pr) / selectedStorage.custom_count;
          } else {
            price = item.custom_price;
          }
        }
      });
    }
    if (order.phone) {
      props.onCreateSpecial(
        {
          order_id: order.id,
          custom_title: selectedStorage.custom_title,
          custom_count: selectedStorage.custom_count,
          custom_price: price,
          custom_id: selectedStorage.custom_id,
        },
        order
      );

      setSelectedStorage(null);
      document.getElementById("custom_count").value = "";
    } else {
      alert("Введите номер телефона клиента.");
    }
  }

  /**
   *
   * @param {string} text
   */

  function textToClipboard(text) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  return (
    <UiModal name="modal" show={props.modalActive}>
      <div className="modal-deal">
        {order ? (
          <div className="modal-deal-wrap">
            {/**
             * modal left side - static
             */}
            <div className="modal-deal-about add">
              {/**
               * modal title
               */}
              <div className="modal-deal-about-title">
                <div className="modal-deal-about-title-wrap">
                  <h3>Заказ №{order.id}</h3>
                </div>
              </div>
              {/**
               * modal notifications + msgs
               */}
              <div className="modal-deal-notes">
                <div className="modal-deal-notes-list-wrap">
                  <div className="modal-deal-info">
                    {!order.isNew ? (
                      <div className="modal-deal-info-form">
                        <UiInputText
                          disabled={true}
                          label="Дата и время создания"
                          placeholder={`${formatDateDMY(
                            order.created_at
                          )}  ${formatDateHM(order.created_at)}`}
                        />
                      </div>
                    ) : null}
                    <div className="modal-deal-info-form">
                      {order.isNew ? (
                        <UiInputText
                          disabled={!order.isNew}
                          label="Телефон покупателя"
                          type="phone"
                          placeholder={`+${order.phone}`}
                          value={order.phone}
                          onChange={(val) => {
                            updateOrderObj("phone", val, false);
                          }}
                        />
                      ) : (
                        <UiInputText
                          label="Телефон покупателя"
                          type="phone"
                          placeholder={`+${order.phone}`}
                          value={userPhone}
                          onChange={(val) => {
                            setUserPhone(val);
                          }}
                          onBlur={(val) => {
                            let _item = {
                              id: order.user_id,
                              phone: val,
                            };
                            props.editUser(_item);
                          }}
                        />
                      )}
                    </div>
                    {!order.isNew ? (
                      <div className="modal-deal-info-form">
                        <UiInputText
                          disabled={true}
                          label="Покупатель"
                          placeholder={order.name}
                        />
                      </div>
                    ) : (
                      <div className="modal-deal-info-form">
                        <UiInputText
                          label="Покупатель"
                          placeholder={order.name}
                          onChange={(val) => updateOrderObj("name", val)}
                        />
                      </div>
                    )}
                    {!order.isNew ? (
                      <div className="modal-deal-info-form">
                        <UiButtonColor
                          color="indigo"
                          text="Позвонить"
                          small={true}
                          onClick={() =>
                            window.open(`tel:${order.phone}`, "_self")
                          }
                        />
                      </div>
                    ) : null}
                    <div className="modal-deal-info-form">
                      <UiButtonColor
                        color="purple"
                        text="Печать чека"
                        small={true}
                        onClick={() => {
                          console.log(order);
                          let _order = order;
                          _order.list = tableArrListOrders;
                          _order.worker_name = inArrayValueByKey(
                            props.curriers,
                            "id",
                            order.worker_id
                          )
                            ? inArrayValueByKey(
                                props.curriers,
                                "id",
                                order.worker_id
                              ).name
                            : "";
                          _order.manager_name = inArrayValueByKey(
                            props.managers,
                            "id",
                            order.manager_id
                          )
                            ? inArrayValueByKey(
                                props.managers,
                                "id",
                                order.manager_id
                              ).name
                            : "";
                          _order.custom_values = props.compositionData;
                          storeData("SET_ORDER", _order);
                          window.open(`/deal/check/${_order.id}`, "_blank");
                        }}
                      />
                    </div>
                    <h3>Данные заказа</h3>
                    <div className="added-list">
                      {tableListOrders.length > 0 && (
                        <UiTableAdaptive
                          titleList={clientsTitles}
                          grid={clientsTitles.length}
                          tableList={tableListOrders}
                          search={false}
                        />
                      )}
                      {specialTableListOrders.length > 0 && (
                        <UiTableAdaptive
                          titleList={clientsTitles}
                          grid={clientsTitles.length}
                          tableList={specialTableListOrders}
                          search={false}
                        />
                      )}
                    </div>
                    {order.is_pay == 0 && (
                      <div>
                        <UiSearchDropDown
                          label="Выбрать товар"
                          value={null}
                          showEmptyVal
                          items={toArrayLabelListKey(props.storage, "title")}
                          onChange={(val) => {
                            setItemComposition(val);
                          }}
                        />
                        <UiButtonColor
                          color="indigo"
                          text="Добавить позицию в заказ"
                          small={true}
                          onClick={() => {
                            let arr =
                              order.composition.length > 0
                                ? order.composition.split(",")
                                : [];
                            arr.push(itemComposition);
                            setCompositionList(arr);
                            updateOrderObj("composition", arr.join(","), true);
                          }}
                        />

                        <UiSwitch
                          label="Добавить костомный товар"
                          value={showCustom}
                          onChange={(val) => setShowCustom(val)}
                        />
                      </div>
                    )}

                    {showCustom ? (
                      <div className="added-list">
                        <UiInputText
                          label="Название"
                          onChange={(val) => setCustomTitle(val)}
                        />
                        <UiInputText
                          label="Кол-во"
                          type="number"
                          onChange={(val) => setCustomCount(val)}
                        />
                        <UiInputText
                          label="Цена"
                          type="number"
                          onChange={(val) => {
                            if (val > 0) setCustomPrice(val);
                          }}
                        />
                      </div>
                    ) : null}
                    {showCustom ? (
                      <UiButtonColor
                        color="brick"
                        text="Добавить костомный товар"
                        small={true}
                        onClick={() => {
                          if (order.phone) {
                            let _price = getTotalPrice(
                              tableListOrders,
                              specialTableListOrders,
                              order.price_delivery,
                              order.price_discount,
                              order.price_discount_bonus,
                              false
                            );
                            order.price = _price;
                            props.onCreateSpecial(
                              {
                                order_id: order.id,
                                custom_title: customTitle,
                                custom_count: customCount,
                                custom_price: customPrice,
                              },
                              order
                            );
                          } else {
                            alert("Введите номер телефона клиента.");
                          }
                        }}
                      />
                    ) : null}
                    {order.is_pay == 0 && (
                      <div>
                        <UiSearchDropDown
                          label="Выбрать товар из 1С"
                          value={null}
                          showEmptyVal
                          items={toArrayLabelListKey(
                            compositionList,
                            "custom_title"
                          )}
                          onChange={(val) => {
                            setSelectedStorage(
                              inArrayValueByKey(compositionList, "id", val)
                            );
                          }}
                        />
                        <UiInputText
                          id="custom_count"
                          label="Кол-во товара:"
                          placeholder="кол-во"
                          type={"number"}
                          onChange={(val) => {
                            let _item = selectedStorage;
                            _item.custom_count = val;
                            setSelectedStorage(_item);
                          }}
                        />
                        <UiButtonColor
                          color="gray"
                          text="Добавить товар из 1C"
                          small={true}
                          onClick={() => {
                            addProductFrom1C();
                          }}
                        />
                      </div>
                    )}
                    <UiSwitch
                      label="Букетная записка"
                      value={order.postcard != null}
                      onChange={(val) => {
                        updateOrderObj("user_postcard", val);
                      }}
                    />
                    {order.user_postcard ? (
                      <UiTextArea
                        label="Открытка"
                        value={`${order.postcard ? order.postcard : ""}`}
                        onChange={(val) => {
                          updateOrderObj("postcard", val);
                        }}
                      />
                    ) : null}

                    <UiDropDown
                      label="Тип оплаты"
                      disabled={order.is_pay == 1}
                      value={order.payment_type}
                      items={toArrayList(
                        OrderConstant.ORDER_PAYMENTS_TYPES_NAMES
                      )}
                      onChange={(val) => {
                        if (order.is_pay == 0)
                          updateOrderObj("payment_type", val, true);
                      }}
                    />
                    <UiDropDown
                      label="Оплата"
                      disabled={order.is_pay == 1}
                      value={order.is_pay}
                      items={toArrayList(OrderConstant.ORDER_IS_PAY)}
                      onChange={(val) => {
                        if (order.is_pay == 0)
                          updateOrderObj("is_pay", val, true);
                      }}
                    />
                    <UiInputText
                      label="Скидка %"
                      type="number"
                      disabled={order.is_pay == 1}
                      placeholder={order.price_discount}
                      value={order.price_discount}
                      onChange={(val) => {
                        if (order.is_pay == 0)
                          updateOrderObj("price_discount", val, true);
                      }}
                    />

<UiInputText
                      label="Скидка БОНУСЫ"
                      type="number"
                      disabled={order.is_pay == 1}
                      placeholder={order.price_discount_bonus}
                      value={order.price_discount_bonus}
                      onChange={(val) => {
                        if (order.is_pay == 0)
                          updateOrderObj("price_discount_bonus", val, true);
                      }}
                    />




                    <UiInputText
                      disabled
                      label="Итого с учетом доставки "
                      placeholder={order.price}
                    />
                    <UiInputText
                      disabled
                      label="Итого с учетом доставки (рассчет)"
                      placeholder={getTotalPrice(
                        tableListOrders,
                        specialTableListOrders,
                        order.price_delivery,
                        order.price_discount,
                        order.price_discount_bonus,
                        false
                      )}
                    />
                    <UiButtonColor
                      color="indigo"
                      text={"Ссылка на форму"}
                      small={true}
                      onClick={() => {
                        if (!order.isNew) {
                          textToClipboard(`${Env.HOST_URL}form/${order.id}`);
                          alert("Ссылка скопирована в буфер обмена !");
                        } else {
                          alert("Создайте вначале заказ !");
                        }
                      }}
                    />

                    {order.is_pay == 0 && (
                      <div>
                        <br />
                        <a
                          href={`${Env.HOST_URL}form/${order.id}`}
                        >{`${Env.HOST_URL}form/${order.id}`}</a>
                        <br />

                        <UiButtonColor
                          color="indigo"
                          text={"Ссылка на оплату онлайн"}
                          small={true}
                          onClick={() => {
                            if (!order.isNew) {
                              alert("Ссылка скопирована в буфер обмена !");
                              textToClipboard(`${Env.HOST_URL}pay/${order.id}`);
                            } else {
                              alert("Создайте вначале заказ !");
                            }
                          }}
                        />

                        <br />
                        <a
                          href={`${Env.HOST_URL}pay/${order.id}`}
                        >{`${Env.HOST_URL}pay/${order.id}`}</a>
                        <br />

                        <UiButtonColor
                          color="lime"
                          text={"Отправить ссылку на оплату в СМС"}
                          small={true}
                          onClick={() => {
                            props.sendSms({
                              id: order.id,
                              user_id: order.user_id,
                              text: `Ссылка на оплату ${Env.HOST_URL}pay/${order.id}`,
                            });
                          }}
                        />
                      </div>
                    )}
                    {order.isNew && (
                      <UiButtonColor
                        color="brick"
                        text={"Создать"}
                        small={true}
                        onClick={() => {
                          setLoad(true);

                          let price = getTotalPrice(
                            tableListOrders,
                            specialTableListOrders,
                            order.price_delivery,
                            order.price_discount,
                            order.price_discount_bonus,
                            false
                          );
                          updateOrderObj("price", price);
                          props.onCreate(order);

                          setLoad(false);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/**
             * modal right side - tabs
             */}
            <div className="modal-deal-info-wrap">
              {/** close button */}
              <button
                className="modal-deal-info-close"
                onClick={() => props.modalClose()}
              >
                <SvgClose className="modal-deal-info-close-icon" />
              </button>
              <div className="modal-deal-info">
                <h3>
                  {!order.isNew ? "Редактировать заказ" : "Создание заказа"}
                </h3>

                <div className="modal-deal-info-form">
                  <h3>Закрепленный персонал</h3>
                  <UiDropDown
                    label="Менеджер принял"
                    value={order.manager_id}
                    showEmptyVal
                    items={managersList}
                    onChange={(val) => updateOrderObj("manager_id", val)}
                  />

                  <h3>Данные доставки</h3>

                  <UiDropDown
                    label="Статус заказа"
                    value={order.status}
                    items={toArrayList(OrderConstant.ORDER_TYPES_NAMES)}
                    onChange={(val) => updateOrderObj("status", val)}
                  />
                  <UiDropDown
                    label="Тип доставки"
                    value={order.moving_types}
                    items={toArrayList(
                      OrderConstant.ORDER_DELIVERY_TYPES_NAMES
                    )}
                    onChange={(val) => {
                      updateOrderObj("moving_types", val);
                      updateOrderObj(
                        "address_a",
                        val == 0 ? "" : OrderConstant.ORDER_SELF_DELIVERY[0],
                        true
                      );
                    }}
                  />

                  <UiSwitch
                    label="Покупатель = получатель"
                    value={order.user_name == null}
                    onChange={(val) => {
                      updateOrderObj("user_ps", val);
                    }}
                  />

                  {!order.user_ps ? (
                    <div>
                      <UiInputText
                        label="Имя получатель"
                        value={order.user_name}
                        onChange={(val) => updateOrderObj("user_name", val)}
                      />
                      <UiInputText
                        label="Телефон получатель"
                        value={order.user_phone}
                        onChange={(val) => updateOrderObj("user_phone", val)}
                      />
                    </div>
                  ) : null}

                  <UiSwitch
                    label="Звонок получателю"
                    value={order.user_call}
                    onChange={(val) => updateOrderObj("user_call", val)}
                  />
                  <UiSwitch
                    label="Чек для покупателя"
                    value={order.user_check}
                    onChange={(val) => updateOrderObj("user_check", val, true)}
                  />

                  {order.moving_types == 0 ? (
                    <div>
                      <UiDropDown
                        label="Курьер"
                        value={order.worker_id}
                        showEmptyVal
                        items={toArrayListKey(props.curriers, "name")}
                        onChange={(val) =>
                          updateOrderObj("worker_id", val, true)
                        }
                      />
                      {/*
                      <UiDropDown
                        label="Опции доставки"
                        value={order.user_call}
                        items={toArrayList(OrderConstant.ORDER_CALL_TYPES_NAMES)}
                        onChange={(val) => updateOrderObj("user_call", val)}
                      />
                  */}
                      <UiInputText
                        label="Дата доставки"
                        type={"date"}
                        value={formatDateYMD(order.date, "-")}
                        onChange={(val) => updateOrderObj("date", val, false)}
                        onBlur={(val) => updateOrderObj("date", val, true)}
                      />

                      <UiInputTimeRange
                        label="Время доставки"
                        value={order.time}
                        onChange={(val) => updateOrderObj("time", val, false)}
                        onBlur={(val) => updateOrderObj("time", val, true)}
                      />

                      <UiInputText
                        label="Адрес доставки"
                        value={order.address_a}
                        onChange={(val) =>
                          updateOrderObj("address_a", val, false)
                        }
                        onBlur={(val) => updateOrderObj("address_a", val, true)}
                      />
                    </div>
                  ) : (
                    <div>
                      <UiInputText
                        label="Дата самовывоза"
                        type={"date"}
                        value={formatDateYMD(order.date, "-")}
                        onChange={(val) => updateOrderObj("date", val, false)}
                        onBlur={(val) => updateOrderObj("date", val, true)}
                      />

                      <UiInputTimeRange
                        label="Время самовывоза"
                        value={order.time}
                        onBlur={(val) => updateOrderObj("time", val, true)}
                      />

                      <UiDropDown
                        label="Адрес самовывоза"
                        value={
                          inArrayValueByKey(
                            toArrayList(
                              OrderConstant.ORDER_SELF_DELIVERY_NAMES
                            ),
                            "text",
                            order.address_a
                          )
                            ? inArrayValueByKey(
                                toArrayList(
                                  OrderConstant.ORDER_SELF_DELIVERY_NAMES
                                ),
                                "text",
                                order.address_a
                              ).value
                            : null
                        }
                        items={toArrayList(
                          OrderConstant.ORDER_SELF_DELIVERY_NAMES
                        )}
                        onChange={(val) => {
                          updateOrderObj(
                            "address_a",
                            OrderConstant.ORDER_SELF_DELIVERY_NAMES[val],
                            true
                          );
                        }}
                      />
                    </div>
                  )}

                  <UiInputText
                    label="Стоимость доставки"
                    placeholder={order.price_delivery}
                    disabled={order.is_pay == 1}
                    type="number"
                    onChange={(val) => {
                      if (order.is_pay != 1)
                        if (val >= 0)
                          updateOrderObj("price_delivery", val, true);
                    }}
                  />

                  <UiTextArea
                    label="Комментарий курьеру"
                    value={`${order.text ? order.text : ""}`}
                    onChange={(val) => updateOrderObj("text", val, false)}
                    onBlur={(val) => {
                      updateOrderObj("text", val, true);
                    }}
                  />
                  <UiTextArea
                    label="Комментарий флориста"
                    value={`${order.comment ? order.comment : ""}`}
                    onChange={(val) => updateOrderObj("comment", val, false)}
                    onBlur={(val) => {
                      updateOrderObj("comment", val, true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <UiLoader show={load} />
    </UiModal>
  );
}

export default UiModalDealAdd;
