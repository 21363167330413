import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSettingList as getSettingListAction,
  getSettingValue as getSettingValueAction,
  updateByKey as updateByKeyAction,
  getIntervalList as getIntervalListAction,
  updateInterval as updateIntervalAction,
  addInterval as addIntervalAction,
} from "../../redux/modules/settings";

import OrderConstant from "../../constants/OrderConstant";

import UiPageText from "../../components/text/UiPageText";
import UiInputText from "../../components/inputs/UiInputText";
import UiSelectDates from "../../components/select/UiSelectDates";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiInputTimeRange from "../../components/inputs/UiInputTimeRange";

import UiSwitch from "../../components/switch/UiSwitch";

import { formatDateYMD, formatDatePlusDayYMD } from "../../utilities/date";
import { addFile } from "../../services/Storage";

import "./SettingStyle.scss";

function SettingPage(props) {
  const intervalTitles = [
    { sort: true, title: "Интервал" },
    { sort: false, title: "Дата" },
    { sort: false, title: "Лимит заказов" },
    { sort: false, title: "Управление" },
  ];

  const [intervalList, setIntervalList] = useState([]);
  const [date, setDate] = useState(new Date());

  const [intervalTime, setIntervalTime] = useState("");
  const [intervalLimit, setIntervalLimit] = useState(0);

  useEffect(() => {
    if (props.login.user) props.getSettingList();
    props.getIntervalList(formatDateYMD(new Date(date), "-"));
  }, [props.login.user]);

  useEffect(() => {
    var arr = [];
    props.settings.listInterval.map((item, index) => {
      arr.push([
        {
          title: intervalTitles[0].title,
          text: item.title,
        },
        {
          title: intervalTitles[1].title,
          text: item.date,
        },
        {
          title: intervalTitles[2].title,
          text: item.order_limit,
        },
        {
          button: "delete",
          onClick: () => {
            if (window.confirm("Удалить элемент ?")) {
              let _item = item;
              _item.is_del = item.is_del == 1 ? 0 : 1;
              props
                .updateInterval(_item)
                .then(() =>
                  props.getIntervalList(formatDateYMD(new Date(date), "-"))
                );
            }
          },
        },
      ]);
    });
    setIntervalList(arr);
  }, [props.settings.listInterval]);

  function upload(file) {
    addFile(file.target.files[0], 0).then((res) => {
      if (res.success) {
        props.updateByKey("home_video_file", res.response);
      } else {
        alert("Файл не является видео");
      }
    });
  }

  let list = props.settings.list
    .filter((it) => {
      return it.show_in_list == 0;
    })
    .map((item, index) => {
      if (item.is_switch) {
        return (
          <UiSwitch
            key={index}
            label={`${item.description}`}
            value={parseInt(item.value) === 1 ? true : false}
            onChange={(val) => props.updateByKey(item.title, val == true ? 1 : 0)}
          />
        );
      }
      if (item.is_file) {
        return (
          <div key={index}>
             <label for="file">{`${item.description} ${item.value}`}</label>
            <input type="file" onChange={upload} />
          </div>
        );
      } else {
        return (
          <UiInputText
            key={index}
            placeholder={item.value}
            label={`${item.description}`}
            onBlur={(val) => {
              props.updateByKey(item.title, val);
            }}
          />
        );
      }
    });

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Настройки" />
        </div>
      </section>
      <section className="page-content">
        <p>Дата интервала</p>
        <UiSelectDates
          onChange={(date) => {
            setDate(date);
            props.getIntervalList(formatDateYMD(new Date(date), "-"));
          }}
          date={date}
        />

        {intervalList.length > 0 ? (
          <UiTableAdaptive
            titleList={intervalTitles}
            grid={intervalTitles.length}
            tableList={intervalList}
          />
        ) : (
          <p>
            <b>В выбранный день нет создано специальных интервалов</b>
          </p>
        )}

        <br />

        <p>Добавить интервала</p>

        <UiInputTimeRange
          label="Интервал"
          onChange={(val) => setIntervalTime(val)}
        />
        <UiInputText
          label={`Лимит`}
          onChange={(val) => setIntervalLimit(val)}
        />

        <UiButtonColor
          color="indigo"
          text="Добавить интервал"
          small={true}
          onClick={() => {
            console.log(intervalTime.split(" - "));
            if (intervalTime.length == 13 && intervalLimit > 0) {
              props
                .addInterval({
                  title: intervalTime,
                  date: formatDateYMD(new Date(date), "-"),
                  order_limit: intervalLimit,
                })
                .then(() => {
                  props.getIntervalList(formatDateYMD(new Date(date), "-"));
                });
            } else {
              alert("Выберете интервал и лимит");
            }
          }}
        />
      </section>
     {/*
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Настройки праздника" />
        </div>
      </section>

 
      <section className="page-content">
        <p>Дата начала праздника</p>
        <UiSelectDates
          onChange={(date) => {
            props.updateByKey(
              "start_wedding_date",
              formatDateYMD(new Date(date), "-")
            );
          }}
          date={new Date(props.getSettingValue("start_wedding_date"))}
        />
        <p>Дата окончания праздника</p>
        <UiSelectDates
          onChange={(date) => {
            props.updateByKey(
              "end_wedding_date",
              formatDateYMD(new Date(date), "-")
            );
          }}
          date={new Date(props.getSettingValue("end_wedding_date"))}
        />
        <UiInputText
          label={`Минимальная сумма корзины для бесплатной доставки в праздник`}
          value={props.getSettingValue(
            "min_wedding_basket_sum_to_free_delivery"
          )}
          onChange={(val) =>
            props.updateByKey("min_wedding_basket_sum_to_free_delivery", val)
          }
        />

        <UiInputText
          label={`Минимальная Сумма для доставки`}
          value={props.getSettingValue("min_sum_to_delivery_wedding")}
          onChange={(val) =>
            props.updateByKey("min_sum_to_delivery_wedding", val)
          }
        />

        <UiInputText
          label={`Мин. сумма для самовывоза`}
          value={props.getSettingValue("min_sum_to_self_delivery_wedding")}
          onChange={(val) =>
            props.updateByKey("min_sum_to_self_delivery_wedding", val)
          }
        />
      </section>
      */}

      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Настройки" />
        </div>
      </section>
      <section className="page-content">{list}</section>
    </div>
  );
}

export default connect(({ login, settings }) => ({ login, settings }), {
  getSettingList: getSettingListAction,
  getSettingValue: getSettingValueAction,
  updateByKey: updateByKeyAction,
  getIntervalList: getIntervalListAction,
  updateInterval: updateIntervalAction,
  addInterval: addIntervalAction,
})(SettingPage);
