import { safelyParseJSON } from "../../../services/Storage";

export default {
  _remCustomImg: function (index, id, state, setState) {
    let arr = state.addPhotos;

    arr.splice(index, 1);
    setState({
      ...state,
      addPhotos: arr,
    });
  },

  convertArrayToReplaces: function (
    _arr,
    order,
    replaces,
    setReplaces,
    updateReplaceItem,
    getStorageItem
  ) {
    let arr = [];
    if (_arr) {
      _arr.map((item, itemIndex) => {
        arr.push([
          {
            button: "delete",
            openClick: () => {
              if (item.id) {
                let _item = item;
                _item.is_del = 1;
                updateReplaceItem(_item).then(() => {
                  getStorageItem(order.title_url).then((item) => {
                    if (item) setReplaces(item.replaces);
                  });
                });
              } else {
                let rem = replaces;
                rem.splice(replaces.length - 1, 1);
                setReplaces(rem);
              }
            },
          },

          { text: `№${itemIndex + 1}` },
          {
            text: `${item.my_storage_item.title} -> ${item.my_storage_replace_item.title}`,
          },
          { text: `${item.count}шт` },
          {
            text: item.composition
              ? `Для варианта #${item.composition}`
              : "Для основного товара",
          },
        ]);
      });
    }
    return arr;
  },

  getTotalPrice: function (composition) {
    let price = 0;
    if (composition !== null) {
      composition.map((item) => {
        price = price + item.custom_count * item.custom_price;
      });
    }
    console.log("SS", composition);
    return price;
  },

  getAdditionalPrice: function (priceArr, selectedStorage) {
    let price = Math.round(selectedStorage.custom_price);
    if (priceArr.length > 0) {
      priceArr.map((item) => {
        if (
          item.count_lower <= selectedStorage.custom_count &&
          item.count_upper >= selectedStorage.custom_count
        ) {
          if (item.custom_price.includes("%")) {
            let pr = item.custom_price.replace("%", "");
            pr =
              (selectedStorage.custom_price -
                (pr / 100) * selectedStorage.custom_price) *
              selectedStorage.custom_count;

            price = Math.round(pr) / selectedStorage.custom_count;
          } else {
            price = Math.round(item.custom_price);
          }
        }
      });
    }
    return price;
  },

  convertArrayToAdditional: function (_arr, openClick, hideClick, editClick) {
    let arr = [];
    _arr.map((item, itemIndex) => {
      let composition = "";
      safelyParseJSON(item.custom_composition).map((item2, index) => {
        composition += `${item2.custom_title} ${item2.custom_count}шт ,`;
      });
      let _item = [
        {
          button: "delete",
          openClick: () => {
            openClick(item.id, itemIndex);
          },
        },
      ];
      /*
      if(hideClick){
        _item.push({
          button: "eye",
          openClick: () => {
            hideClick(item, itemIndex);
          },
        });
      }
        */
      if(editClick){
        _item.push({
          button: "edit",
          openClick: () => {
            editClick(item, itemIndex);
          },
        }); 
      }

      _item.push({ text: `${item.custom_title} (${composition})` });
      _item.push({ text: `${item.custom_price} ₽` });
      _item.push({ text: item.custom_count });

      arr.push(_item);
    });
    console.log("ARR ", arr);

    return arr;
  },
};
