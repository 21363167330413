import React, { useState, useEffect } from "react";
import { EditorState,  convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from "draft-js-import-html";

import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
 
import "./UiTextAreaWysiwyg.scss";

const UiTextAreaWysiwyg = (props) => {
  const [state, setState] = useState();

  useEffect(() => {
    if( props.initValue){ 
      var contentState = stateFromHTML(props.initValue);
      var editorState = EditorState.createWithContent(contentState);
      setState( editorState )
    } else {
      setState( EditorState.createEmpty() )
    }
  }, [ props.initValue])

  return (
    <div className="form-wrap textarea-wysiwyg"> 
      {props.label && <label>{props.label}</label>}
      <Editor
        wrapperClassName="textarea-wysiwyg-wrapper"
        toolbarClassName="textarea-wysiwyg-toolbar"
        editorClassName="textarea-wysiwyg-editor"
        localization={{
          locale: "ru",
        }}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "embedded",
            "emoji",
            "image",
            "history",
          ],
          fontSize: {
            options: [12, 14, 16, 18, 24, 30],
          },
          colorPicker: {
            colors: [
              "rgba(106, 44, 133, 1)",
              "rgba(106, 44, 133, 0.8)",
              "rgba(106, 44, 133, 0.6)",
              "rgba(106, 44, 133, 0.4)",
              "rgba(106, 44, 133, 0.2)",
              "rgba(211, 59, 90, 1)",
              "rgba(211, 59, 90, 0.8)",
              "rgba(211, 59, 90, 0.6)",
              "rgba(211, 59, 90, 0.4)",
              "rgba(211, 59, 90, 0.2)",
              "rgba(192, 58, 37, 1)",
              "rgba(192, 58, 37, 0.8)",
              "rgba(192, 58, 37, 0.6)",
              "rgba(192, 58, 37, 0.4)",
              "rgba(192, 58, 37, 0.2)",
              "rgba(254, 151, 0, 1)",
              "rgba(254, 151, 0, 0.8)",
              "rgba(254, 151, 0, 0.6)",
              "rgba(254, 151, 0, 0.4)",
              "rgba(254, 151, 0, 0.2)",
              "rgba(123, 170, 47, 1)",
              "rgba(123, 170, 47, 0.8)",
              "rgba(123, 170, 47, 0.6)",
              "rgba(123, 170, 47, 0.4)",
              "rgba(123, 170, 47, 0.2)",
              "rgba(43, 143, 143, 1)",
              "rgba(43, 143, 143, 0.8)",
              "rgba(43, 143, 143, 0.6)",
              "rgba(43, 143, 143, 0.4)",
              "rgba(43, 143, 143, 0.2)",
              "rgba(90, 107, 255, 1)",
              "rgba(90, 107, 255, 0.8)",
              "rgba(90, 107, 255, 0.6)",
              "rgba(90, 107, 255, 0.4)",
              "rgba(90, 107, 255, 0.2)",
              "rgba(0, 0, 0, 1)",
              "rgba(0, 0, 0, 0.8)",
              "rgba(112, 112, 112, 1)",
              "rgba(160, 167, 169, 1)",
              "rgba(205, 211, 216, 1)",
              "rgba(244, 244, 244, 1))",
              "rgba(255, 255, 255, 1)",
            ],
          },
        }}
        editorState={state}
        onEditorStateChange={(val) => { 
          setState(val)
          props.onChange(draftToHtml(convertToRaw(val.getCurrentContent())) )
        }}
      />
    </div>
  );
};
export default UiTextAreaWysiwyg;
